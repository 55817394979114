<template>
	<div class="h-100">
		<layouts-loading text="iniciando sesión" />
	</div>
</template>

<script>
	import { onMounted } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import router from '@/router';
	import LocationRepository from '@/repositories/LocationRepository';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';

	export default {
		setup() {
			const { params: { provider }} = useRoute();
			const { redirectNext } = composableRedirect();
			const { catchError } = composableForm();

			onMounted(() => {
				store.dispatch('auth/socialServer', {provider: provider}).then(onSuccess).catch(onError);
			});

			const onSuccess = () => {
				store.dispatch('auth/me').then(setLocation).catch(onError);
			}

			const setLocation = () => {
				LocationRepository.default()
					.then(response => {
						if(response.data) {
							store.dispatch('location/setAddress', {
								id: response.data.address_id,
								description: response.data.pivot.description,
								country: response.data.country,
								administrative_area_level_1: response.data.administrative_area_level_1,
								administrative_area_level_2: response.data.administrative_area_level_2,
								administrative_area_level_3: response.data.administrative_area_level_3,
								locality: response.data.locality,
								sublocality: response.data.sublocality,
								route: response.data.route,
								street_number: response.data.street_number,
								postal_code: response.data.postal_code,
								division: response.data.division,
								geolocation: {
									lat: response.data.geolocation.coordinates[0],
									lng: response.data.geolocation.coordinates[1]
								}
							}).then(redirectNext).catch(catchError);
						}else{
							redirectNext();
						}
					}).catch(catchError);
			}

			const onError = (e) => {
				catchError(e);
				router.push({ name: 'identify.index' });
			}

			return { provider };
		}
	}
</script>